<template>
  <div class="card sign-in-card card-primary">
    <div class="card-body">
      <div class="d-flex justify-content-start gap-4 mb-5">
        <router-link to="/">
          <img src="/svgs/isologo.svg" />
        </router-link>
        <h4 class="fw-500">Sign In</h4>
      </div>

      <input type="text" placeholder="Email" v-model="form.email" required />
      <input
        type="text"
        placeholder="Password"
        v-model="form.password"
        required
      />

      <button class="btn btn-dark btn-block mt-5">Log in</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      email: null,
      password: null,
    },
  }),
};
</script>
